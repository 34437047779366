//import Validate from "@/plugins/Validation/Branch/branch"
export default {
    data() {
        return {
            loading: false,
            TableLoading: false,
            dialogChangePassword: false,
            roles: [],
            trucks: [],
            selectedRole: '',
            selectedEditRole: '',
            selectedTruck: '',
            userID: '',
            search:'',
            server_errors: {
                email: '', roleId: ''
            },
            truck_user: {},
            rolesID: {},
            truck_user_edit: {},

            listType: [
                {
                    id: 1,
                    name: "ສາຂາຫຼັກ",
                    key: "branch"
                } ,
                {
                    id: 2,
                    name: "ສາຂາຕົວແທນ",
                    key: "franchise"
                }
            ],
            selectedBranch:"",
            new_password: '',
            headers: [{
                text: "User ID", align: "start", sortable: false, value: "user_card_id",
            }, {
                text: "Name", align: "start", sortable: false, value: "user_name",
            }, {text: "Email", value: "user_email"}, {text: "ທະບຽນລົດ", value: "license_plate"}, {
                text: "User Role",
                value: "roleUserName"
            }, {text: "Actions", value: "actions", sortable: false},],
            truck_users: [],
            nameRules: [v => !!v || 'Name is required', v => (v && v.length >= 2) || 'Name must be less than 2 characters',],
            emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",],
            phoneRules: [(v) => !!v || "Phone is required", v => (v && v.length >= 4 && v.length <= 11) || 'Phone number must be  4 - 11 numbers',],
            ruleRole: [(v) => !!v || 'User Role is required',],
            ruleTruck: [(v) => !!v || 'Truck is required',],
            passwordRules: [(v) => !!v || "Password is required", (v) => (v && v.length >= 6) || "Password must be more than 6 characters",],
            toast: {
                value: true, color: 'success', msg: 'Success'
            },
            toast_error: {
                value: true, color: 'error', msg: 'Something when wrong!'
            }
        }
    },
    methods: {
        getData() {
            this.TableLoading = true;
            this.$admin.get('list-user-trucks').then(res => {
                setTimeout(() => {
                    this.truck_users = res.data.listUsers;
                    this.TableLoading = false;
                }, 100);
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });
            this.$admin.get('list-role').then(res => {
                // this.roles = res.data.roles;
                var rolesUser = ["drivermanager", "driveruser", "driverandbranch",];
                this.roles = res.data.roles.filter(item => rolesUser.includes(item.name));
                this.TableLoading = false;
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });

            this.$admin.get('list-water-truck').then(res => {
                setTimeout(() => {
                    this.trucks = res.data.trucks;
                    this.TableLoading = false;
                }, 100);
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });
        },
        OpenModalAdd() {
            this.$store.commit("modalAdd_State", true);
        }, AddUser() {
            if (this.$refs.form.validate() == true) {
                this.Submit();
            }
        },

        Submit() {
            this.loading = true;
            this.truck_user.roleId = this.selectedRole;
            this.truck_user.truckId = this.selectedTruck;
            this.$admin.post('/add-user-truck', this.truck_user)
                .then(res => {
                    if (res.data.success == true) {
                        setTimeout(() => {
                            this.loading = false;
                            this.CloseModalAdd();
                            this.truck_user = {}, this.getData();
                            this.reset();
                            this.$store.commit("Toast_State", this.toast);
                        }, 300);
                    }
                }).catch(error => {
                this.loading = false;
                this.$store.commit("Toast_State", this.toast_error);
                if (error.response.status == 422) {
                    var obj = error.response.data.errors;
                    for (let [key, user] of Object.entries(obj)) {
                        this.server_errors[key] = user[0];
                    }
                }
            });
        }, CloseModalAdd() {
            this.truck_user = {}, this.reset();
            this.$store.commit("modalAdd_State", false);
        }, OpenModalEdit(item) {
            this.truck_user_edit = item;
            this.$store.commit("modalEdit_State", true);
        },

        Update() {
            if (this.$refs.form.validate() == true) {
                this.loading = true;
                this.$admin.put('edit-user-truck/' + this.truck_user_edit.driverUserId, {
                    name: this.truck_user_edit.user_name,
                    email: this.truck_user_edit.user_email,
                    truckId: this.truck_user_edit.truckId,
                    roleId: this.truck_user_edit.roleUser
                })
                    .then(res => {
                        if (res.data.success == true) {
                            setTimeout(() => {
                                this.loading = false;
                                this.CloseModalEdit();
                                this.truck_user_edit = {};
                                this.getData();
                                this.reset();
                                this.$store.commit("Toast_State", this.toast);
                            }, 300);
                        }
                    }).catch(error => {
                    this.loading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                    this.getData();
                    if (error.response.status == 422) {
                        var obj = error.response.data.errors;
                        for (let [key, truck_user] of Object.entries(obj)) {
                            this.server_errors[key] = truck_user[0];
                        }
                    }
                });
            }
        },

        CloseModalEdit() {
            this.truck_user_edit = {};
            this.reset();
            this.$store.commit("modalEdit_State", false);
        },


        closeDelete() {
            this.$store.commit("modalDelete_State", false);
        }, deleteItem(driverUserId) {
            this.userID = driverUserId;
            this.$store.commit("modalDelete_State", true);
        }, deleteItemConfirm() {
            this.loading = true;
            this.$admin.delete('delete-user-truck/' + this.userID).then(res => {
                if (res.data.success == true) {
                    setTimeout(() => {
                        this.getData();
                        this.$store.commit("modalDelete_State", false);
                        this.loading = false;
                        this.$store.commit("Toast_State", this.toast);
                    }, 300);
                }
            }).catch(() => {
                this.getData();
                this.$store.commit("modalDelete_State", false);
                this.loading = false;
            })
        }, OpenchangePassword(userId) {
            this.new_password = '', this.userID = userId;
            this.dialogChangePassword = true;
        }, ChangePassword() {
            if (this.$refs.chang.validate() == true) {
                this.loading = true;
                this.$admin.post('change-password', {
                    password: this.new_password, userId: this.userID,
                }).then(res => {
                    if (res.data.success == true) {
                        setTimeout(() => {
                            this.dialogChangePassword = false;
                            this.new_password = '', this.loading = false;
                            this.getData();
                            this.$store.commit("Toast_State", this.toast);
                        }, 300)
                    }
                }).catch(() => {
                    this.loading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                })
            }
        }, reset() {
            this.$refs.form.reset();
        },
    },
    computed: {
        resultSearch() {
            if (this.search) {
                return this.truck_users.filter((item) => {
                    let filtered = true;
                    if (this.search.length > 0) {
                        filtered = item.user_name
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) || item.user_email
                            .toLowerCase()
                            .includes(this.search.toLowerCase());
                    }
                    return filtered;
                });
            } else {
                return this.truck_users;
            }
        },
    },
    watch: {
        'truck_user.email': function () {
            this.server_errors.email = '';
        }, 'truck_user_edit.user_email': function () {
            this.server_errors.email = '';
        },
    }, created() {
        this.getData();
    },
}
