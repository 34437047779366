<template>
  <div>
    <v-row>
      <v-col>
        <h3>
           User ຂົນສົ່ງ
          <span class="primary-color">({{ truck_users.length }})</span>
        </h3>
      </v-col>
      <v-col>
        <v-col cols>
          <v-text-field
              outlined
              dense
              clearable
              label="ຄົ້ນຫາ ດ້ວຍ ຊື່, Email"
              type="text"
              v-model="search"
          >
          </v-text-field>
        </v-col>
      </v-col>
      <v-col class="d-flex justify-end">
        <template>
          <div class="my-2">
            <v-btn class="btn-primary" fab small dark @click="OpenModalAdd()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </template>
      </v-col>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="resultSearch"
        :items-per-page="15"
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        loading-text="Loading... Please wait"
    >
      <!--Action -->

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="OpenchangePassword(item.userId)">
          mdi-key
        </v-icon>
        <v-icon small class="mr-2" @click="OpenModalEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item.driverUserId)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <!--Delete Modal-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Add New User</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Name*"
                        v-model="truck_user.name"
                        :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <!--
                  <v-col cols="12">
                    <v-text-field
                      label="Phone*"
                      required
                      v-model="user.phone"
                      :rules="phoneRules"
                      type="number"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.phone }}
                    </p>
                  </v-col>
                  -->
                  <v-col cols="12">
                    <v-text-field
                        label="Email*"
                        v-model="truck_user.email"
                        :rules="emailRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.email }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        required
                        v-model="selectedTruck"
                        :items="trucks"
                        item-text="license_plate"
                        item-value="id"
                        label="ເລືອກລົດ *"
                        :rules="ruleTruck"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                        v-model="selectedRole"
                        :items="roles"
                        item-text="display_name"
                        item-value="id"
                        label="User Role *"
                        multiple
                        :rules="ruleRole"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Password*"
                        type="password"
                        v-model="truck_user.password"
                        :rules="passwordRules"
                        @keyup.enter="AddUser"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalAdd()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="AddUser()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

    <!--Edit Modal-->

    <ModalEdit>
      <template @close="close" v-slot="">
        <v-card>
          <v-card-title>
            <span class="headline">Update Delivery User</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Name*"
                        v-model="truck_user_edit.user_name"
                        :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <!--
                  <v-col cols="12">
                    <v-text-field
                      label="Phone*"
                      required
                      v-model="user.phone"
                      :rules="phoneRules"
                      type="number"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.phone }}
                    </p>
                  </v-col>
                  -->
                  <v-col cols="12">
                    <v-text-field
                        label="Email*"
                        v-model="truck_user_edit.user_email"
                        :rules="emailRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.email }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        v-model="truck_user_edit.truckId"
                        :items="trucks"
                        item-text="license_plate"
                        item-value="id"
                        label="ເລືອກລົດ *"
                        :rules="ruleTruck"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        v-model="truck_user_edit.roleUser"
                        :items="roles"
                        item-text="name"
                        item-value="id"
                        label="User Role *"
                        multiple
                    >
                    </v-select>
                    <p class="errors">
                      {{ server_errors.roleId }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalEdit()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="Update()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalEdit>

    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete()"
          >Cancel
          </v-btn
          >
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="deleteItemConfirm()"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>

    <!--Change Password -->
    <v-dialog v-model="dialogChangePassword" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Change Password</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="chang" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      label="New Password*"
                      type="password"
                      v-model="new_password"
                      :rules="passwordRules"
                      required
                      @keyup.enter="ChangePassword"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialogChangePassword = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="ChangePassword"
          >
            Change
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import manage from "../../../mixins/delivery/delivery_user";

export default {
  mixins: [manage],
  metaInfo() {
    return {
      title: `ຂໍ້ມູນ User ຈັດສົ່ງນ້ຳ`,
      meta: [
        { name: 'description', content: 'ຂໍ້ມູນ User ຈັດສົ່ງນ້ຳ'},
      ]
    }
  },
};
</script>

<style>
.primary-color {
  color: #00c1d2;
}

@import "../../../../public/scss/main.scss";
</style>